<template>
  <div class="mx-6 mb-5">
    <v-card flat :color="cardColor" height="246px">
      <div class="mx-5 font-15px">
        <div class="mb-2 pt-2">
          売上
          <span class="fw-700">￥ <Counter :endVal="totalEarnings" /> </span>
        </div>
        <div class="solid-border"></div>
        <div class="ml-4 my-2">
          フード：￥ <Counter :endVal="foodEarnings" />
        </div>
        <div class="dotted-border"></div>
        <div class="ml-4 my-2">
          ドリンク：￥ <Counter :endVal="drinksEarnings" />
        </div>
        <div class="dotted-border"></div>
        <div class="ml-4 my-2">
          デリバリー：￥ <Counter :endVal="deliveryEarnings" /> （<Counter
            :endVal="deliveryCases"
          />件)
        </div>
        <div class="dotted-border"></div>
        <div class="ml-4 my-2">
          テイクアウト：￥ <Counter :endVal="takeoutEarnings" /> （
          <Counter :endVal="takeoutCases" />件)
        </div>
        <div class="dotted-border"></div>
        <div class="ml-4 my-2">
          その他：￥ <Counter :endVal="otherEarnings" />
        </div>
      </div>
    </v-card>
    <div class="d-flex justify-space-between mt-3">
      <v-card width="95px" height="63px" :color="cardColor" flat>
        <div class="mt-1">
          <div class="text-center font-10px text-87">総客数</div>
          <div>
            <v-icon :style="{ fill: iconColor }">$multipleCustomers</v-icon>
          </div>
          <div class="text-center mt-n1">
            <span class="font-16px fw-700"
              ><Counter :endVal="numberOfCustomers"/></span
            ><span class="fw-700 font-8px"> 人</span>
          </div>
        </div>
      </v-card>
      <v-card width="95px" height="63px" :color="cardColor" flat>
        <div class="mt-1">
          <div class="text-center font-10px text-87">総組数</div>
          <div class="d-flex justify-center mt-1">
            <v-icon :style="{ fill: iconColor }">$pairCustomers</v-icon>
            <div class="text-center pl-2">
              <span class="font-16px fw-700"
                ><Counter :endVal="numberOfGroups" /> </span
              ><span class="fw-700 font-8px"> 組</span>
            </div>
          </div>
        </div>
      </v-card>
      <v-card width="95px" height="63px" :color="cardColor" flat>
        <div class="mt-1">
          <div class="text-center font-10px text-87">客単価</div>
          <div class="text-center">
            <span class="fw-700 font-10px">￥</span>
            <span class="font-16px fw-700">
              <Counter :endVal="averageSalesPerCustomer" />
            </span>
          </div>
          <div class="d-flex justify-center">
            <v-icon :style="{ fill: iconColor }">$singleCustomer</v-icon>
          </div>
        </div>
      </v-card>
    </div>
    <div class="d-flex justify-space-between mt-3">
      <v-card width="95px" height="63px" :color="cardColor" flat>
        <div class="mt-1">
          <div class="text-center font-10px text-87">店内利用客数</div>
          <div>
            <v-icon :style="{ fill: iconColor }">$groupsInStore</v-icon>
          </div>
          <div class="text-center mt-n1">
            <span class="font-16px fw-700"
              ><Counter :endVal="numberOfCustomerStore"/></span
            ><span class="fw-700 font-8px"> 人</span>
          </div>
        </div>
      </v-card>
      <v-card width="95px" height="63px" :color="cardColor" flat>
        <div class="mt-1">
          <div class="text-center font-10px text-87">店内利用組数</div>
          <div class="d-flex justify-center mt-1">
            <div>
              <v-icon :style="{ fill: iconColor }">$customersInStore</v-icon>
            </div>
            <div class="text-center pl-0">
              <span class="font-16px fw-700"
                ><Counter :endVal="numberOfGroupStore"/></span
              ><span class="fw-700 font-8px"> 組</span>
            </div>
          </div>
        </div>
      </v-card>
      <v-card width="95px" height="63px" :color="cardColor" flat>
        <div class="mt-1">
          <div class="text-center font-10px text-87">店内利用客単価</div>
          <div class="text-center">
            <span class="fw-700 font-10px">￥</span>
            <span class="font-16px fw-700">
              <Counter :endVal="averageSalesPerCustomerInStore"
            /></span>
          </div>
          <div class="d-flex justify-center">
            <v-icon :style="{ fill: iconColor }">$instoreCustomer</v-icon>
          </div>
        </div>
      </v-card>
    </div>
    <div v-if="tabFor === 'lunchSales' && type === 'daily'">
      <div class="d-flex justify-space-between mt-6 text-888 font-16px">
        <div>ランチ報告</div>
        <div>
          最終編集者：{{ user ? user.surname + " " + user.name : "NA" }}
        </div>
      </div>
      <v-card
        v-if="notes"
        color="transparent"
        class="report-border rounded mt-2"
        flat
      >
        <div class="ml-7 mr-5 my-5 font-16px">
          {{ notes }}
        </div>
      </v-card>
    </div>
    <div v-if="tabFor === 'dinnerSales' && type === 'daily'">
      <div class="d-flex justify-space-between mt-6 text-888 font-16px">
        <div>ディナー報告</div>
        <div>
          最終編集者：{{ user ? user.surname + " " + user.name : "NA" }}
        </div>
      </div>
      <v-card
        v-if="notes"
        color="transparent"
        class="report-border rounded mt-2"
        flat
      >
        <div class="ml-7 mr-5 my-5 font-16px">
          {{ notes }}
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Counter from "../../global/CounterUp.vue";
export default {
  name: "SalesTab",
  components: {
    Counter
  },
  props: {
    tabFor: {
      type: String,
      required: false,
      default: "sameDaySales"
    },
    report: {
      type: Object,
      default: () => {}
    },
    lunchReport: {
      required: false,
      default: ""
    },
    dinnerReport: {
      type: String,
      required: false,
      default: ""
    },
    finalReportEditor: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  watch: {
    report(newVal) {
      this.renderReportValues();
    }
  },
  computed: {
    iconColor() {
      if (this.tabFor === "sameDaySales") {
        return "#6E77F0";
      } else if (this.tabFor === "lunchSales") {
        return "#E5D02D";
      } else if (this.tabFor === "dinnerSales") {
        return "#E98B50";
      } else {
        return "#6E77F0";
      }
    },
    cardColor() {
      if (this.tabFor === "sameDaySales") {
        return "#F4F8FC";
      } else if (this.tabFor === "lunchSales") {
        return "#FFFCE9";
      } else if (this.tabFor === "dinnerSales") {
        return "#FFF7F2";
      } else {
        return "#F4F8FC";
      }
    }
  },
  async mounted() {
    await this.renderReportValues();
  },
  data() {
    return {
      totalEarnings: 0,
      foodEarnings: 0,
      drinksEarnings: 0,
      deliveryEarnings: 0,
      deliveryCases: 0,
      takeoutEarnings: 0,
      takeoutCases: 0,
      otherEarnings: 0,
      averageSalesPerCustomer: 0,
      averageSalesPerCustomerInStore: 0,
      numberOfCustomers: 0,
      numberOfGroups: 0,
      numberOfCustomerStore: 0,
      numberOfGroupStore: 0,
      notes: "",

      user: {}
    };
  },
  methods: {
    renderReportValues() {
      this.totalEarnings = this.report.total_earnings;
      this.foodEarnings = this.report.food_earnings;
      this.drinksEarnings = this.report.drinks_earnings;
      this.deliveryEarnings = this.report.delivery_earnings;
      this.deliveryCases = this.report.delivery_cases;
      this.takeoutEarnings = this.report.takeout_earnings;
      this.takeoutCases = this.report.takeout_cases;
      this.otherEarnings = this.report.other_earnings;
      this.averageSalesPerCustomer = this.report.average_sales_per_customer;
      this.averageSalesPerCustomerInStore = this.report.average_sales_per_customer_in_store;
      this.numberOfCustomers = this.report.number_of_customers;
      this.numberOfGroups = this.report.number_of_groups;
      this.numberOfCustomerStore = this.report.number_of_customer_store;
      this.numberOfGroupStore = this.report.number_of_group_store;
      this.notes = this.report.notes;
      this.user = this.report.user;
    }
  }
};
</script>

<style lang="scss" scoped>
.solid-border {
  border-bottom: 1px solid #c9c9c9 !important;
}
.dotted-border {
  border-bottom: 0.5px dashed #c9c9c9 !important;
}
.report-border {
  border: 1px solid #c9c9c9 !important;
}
</style>
