<template>
  <v-card flat color="#F5FFF4" height="272px" class="mx-6 mb-5">
    <div class="mx-7 font-15px">
      <div class="mb-2 pt-2">
        総コスト
        <span class="fw-700"
          >￥
          <Counter :endVal="totalCost" />
        </span>
      </div>
      <div class="solid-border"></div>

      <div class="ml-4 my-2">
        人件費 (L)：￥
        <Counter :endVal="personnelCost" />
      </div>
      <div class="dotted-border"></div>
      <div class="ml-4 my-2">
        食材費 (F)：￥ <Counter :endVal="ingredientCost" />
      </div>
      <div class="dotted-border"></div>
      <div class="ml-4 my-2">
        家賃 (R)：￥
        <Counter :endVal="rent" />
      </div>
      <div class="dotted-border"></div>
      <div class="ml-4 my-2 text-center">
        <div class="ml-n9">プラットフォーム手数料 (P)</div>
        <div class="">
          ：￥
          <Counter :endVal="platfromFee" />
        </div>
      </div>
      <div class="dotted-border"></div>
      <div class="ml-4 my-2">
        その他 (O)：￥
        <Counter :endVal="other" />
      </div>
    </div>
  </v-card>
</template>

<script>
import Counter from "../../global/CounterUp.vue";

export default {
  name: "CostTab",
  components: {
    Counter
  },
  props: {
    cost: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    cost(newVal) {
      this.renderCostValues();
    }
  },
  computed: {},
  async mounted() {
    await this.renderCostValues();
  },
  data() {
    return {
      totalCost: 0,
      ingredientCost: 0,
      personnelCost: 0,
      rent: 0,
      platfromFee: 0,
      other: 0
    };
  },
  methods: {
    async renderCostValues() {
      this.totalCost = this.cost.cost;
      this.ingredientCost = this.cost.foodstuff_cost;
      this.personnelCost = this.cost.labor_cost;
      this.rent = this.cost.rent_cost;
      this.platfromFee = this.cost.platform_cost;
      this.other = this.cost.other_cost;
    }
  }
};
</script>

<style lang="scss" scoped>
.solid-border {
  border-bottom: 1px solid #c9c9c9 !important;
}
.dotted-border {
  border-bottom: 0.5px dashed #c9c9c9 !important;
}
</style>
