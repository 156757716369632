<template>
  <v-card color="#EFF5FF" class="my-3 mx-4">
    <div class="pb-3"></div>
    <v-card
      v-if="dateCard"
      class="mx-3 white--text text-center"
      color="#0094FF"
      flat
      height="28px"
    >
      {{ month }}月{{ startDate }}日～{{ currentDate }}日 累計
    </v-card>
    <v-simple-table class="simple-table-1 mx-3">
      <tbody>
        <tr class="ml-2">
          <td class="primary--text font-14px pl-2">
            売上
          </td>
          <td class="text-left font-16px fw-700">
            ￥
            <Counter :endVal="earnings" />
          </td>
          <td class="font-12px text-85 text-left">
            達成率 {{ achievementRate }}%
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table class="simple-table-2 mx-3">
      <tbody class="pt-n10">
        <tr>
          <td class="primary--text font-14px  pl-2">
            <div class="text-left">( 現金売上 )</div>
          </td>
          <td class="font-16px fw-700">
            <div class="text-left">
              ￥
              <Counter :endVal="cashSales" />
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table class="simple-table-3 mx-3">
      <tbody>
        <tr>
          <td class="primary--text font-14px  pl-2">
            コスト
          </td>
          <td class="text-left font-16px fw-700">
            ￥
            <Counter :endVal="cost.cost" />
          </td>
          <td class="font-12px text-85"></td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table class="simple-table-4 mx-3">
      <tbody>
        <tr>
          <td class="primary--text font-14px  pl-2">
            損益
          </td>
          <td class="text-left font-16px fw-700">
            ￥
            <Counter :endVal="profit" />
          </td>
          <td class="font-12px text-85 text-center"></td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import Counter from "../../global/CounterUp.vue";
export default {
  name: "DailySalesCard",
  components: {
    Counter
  },
  props: {
    earnings: {
      type: Number,
      required: true
    },
    achievementRate: {
      type: Number,
      default: 0,
      required: true
    },
    cashSales: {
      type: Number,
      default: 0,
      required: true
    },
    cost: {
      type: Object,
      default: () => {},
      required: true
    },
    profit: {
      type: Number,
      default: 0,
      required: true
    },
    dateCard: {
      type: Boolean,
      default: false,
      required: false
    },
    startDate: {
      type: Number,
      default: null,
      required: false
    },
    currentDate: {
      type: Number,
      default: null,
      required: false
    },
    month: {
      type: Number,
      default: null,
      required: false
    }
  }
};
</script>

<style lang="scss">
.simple-table-1 {
  table {
    background-color: #eff5ff !important;
    tbody {
      color: #000000;
      tr {
        &:hover {
          background-color: #eff5ff !important;
        }
        td {
          width: 32%;
          padding: 0px !important;
          height: 36px !important;
          color: #333333;
          text-align: left !important;
          border-bottom: 0.5px dashed #c9c9c9 !important;
        }
      }
    }
  }
}
.simple-table-2 {
  table {
    background-color: #eff5ff !important;
    tbody {
      color: #000000;
      tr {
        &:hover {
          background-color: #eff5ff !important;
        }
        td {
          width: 32%;
          padding: 0px !important;
          height: 36px !important;
          color: #333333;
          text-align: left;
          border-bottom: 0.5px dashed #c9c9c9 !important;
        }
      }
    }
  }
}
.simple-table-3 {
  table {
    background-color: #eff5ff !important;
    tbody {
      color: #000000;
      tr {
        &:hover {
          background-color: #eff5ff !important;
        }
        td {
          width: 32%;
          padding: 0px !important;
          height: 36px !important;
          color: #333333;
          text-align: left;
          border-bottom: 0.5px dashed #c9c9c9 !important;
        }
      }
    }
  }
}
.simple-table-4 {
  table {
    background-color: #eff5ff !important;
    tbody {
      color: #000000;
      tr {
        &:hover {
          background-color: #eff5ff !important;
        }
        td {
          padding: 0px !important;
          width: 32%;
          height: 36px !important;
          color: #333333;
          text-align: left;
        }
      }
    }
  }
}
</style>
