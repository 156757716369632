<template>
  <v-container v-if="!dailyReport.month" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>
  <v-container class="position-container" v-else-if="dailyReport.month">
    <v-card color="transparent" flat class="position-card">
      <v-card
        v-if="!dailyReport.is_goal_set && getShopAuthUser"
        @click.prevent="$router.push({ name: 'GoalSetting' })"
        :disabled="getShopAuthUser.user_role == 'P'"
        color="#FFE1E1"
        height="auto"
      >
        <v-card-title class="justify-center font-16px fw-500">
          <v-icon class="pr-2" size="25">$warning</v-icon>
          売上目標が未設定です。
        </v-card-title>
        <v-card-subtitle
          class="text-center mt-n6"
          v-if="getShopAuthUser.user_role != 'P'"
        >
          <div class="warning-text font-14px fw-500 ml-4">
            売上目標を設定する
          </div>
        </v-card-subtitle>
      </v-card>
      <v-card
        v-if="!dailyReport.is_fixed_cost_set && getShopAuthUser"
        @click.prevent="$router.push({ name: 'fixedCostList' })"
        :disabled="getShopAuthUser.user_role == 'P'"
        class="my-5"
        color="#FFE1E1"
        height="auto"
      >
        <v-card-title class="justify-center font-16px fw-500">
          <v-icon class="pr-2" size="25">$warning</v-icon>
          月コストが未設定です。
        </v-card-title>
        <v-card-subtitle
          class="text-center mt-n6"
          v-if="getShopAuthUser.user_role != 'P'"
        >
          <div class="warning-text font-14px fw-500 ml-6">
            月コストを設定する
          </div></v-card-subtitle
        >
      </v-card>
      <v-sheet tile height="44px" class="d-flex rounded" color="#495BC4">
        <v-btn icon class="mt-1 ml-1" @click="previousDayReport">
          <v-icon size="12">$whiteArrowLeft</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <div class="font-18px white--text">
            {{ dailyReport.month }}月{{ dailyReport.day }}日({{ dayName }})
          </div>
          <div class="d-flex font-18px white--text ml-4">
            <v-icon size="20" class="mt-1" color="#ffffff">
              {{
                dailyReport.weather.lunch_weather == "1"
                  ? "$whiteSun"
                  : dailyReport.weather.lunch_weather == "2"
                  ? "$whiteCloud"
                  : dailyReport.weather.lunch_weather == "3"
                  ? "$umbrella"
                  : ""
              }}
            </v-icon>
            <div class="ml-1">
              {{
                dailyReport.weather.lunch_weather == "1"
                  ? "晴れ"
                  : dailyReport.weather.lunch_weather == "2"
                  ? "曇り"
                  : dailyReport.weather.lunch_weather == "3"
                  ? "雨"
                  : ""
              }}
            </div>
            <v-icon size="20" class="mt-1" color="#ffffff">
              {{
                dailyReport.weather.dinner_weather == "1"
                  ? "$whiteSun"
                  : dailyReport.weather.dinner_weather == "2"
                  ? "$whiteCloud"
                  : dailyReport.weather.dinner_weather == "3"
                  ? "$umbrella"
                  : ""
              }}
            </v-icon>
            <div class="ml-1">
              {{
                dailyReport.weather.dinner_weather == "1"
                  ? "晴れ"
                  : dailyReport.weather.dinner_weather == "2"
                  ? "曇り"
                  : dailyReport.weather.dinner_weather == "3"
                  ? "雨"
                  : ""
              }}
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon class="mr-1 mt-1 rotate-arrow-icon" @click="nextDayReport">
          <v-icon size="12">$whiteArrowLeft</v-icon>
        </v-btn>
      </v-sheet>
      <v-card class="mt-4">
        <div class="mx-3 pt-4 d-flex justify-space-between">
          <v-card
            v-if="!isReported"
            class="d-flex justify-center align-center"
            flat
            width="84px"
            height="24px"
            color="#FFC9C9"
          >
            <div class="font-14px font-333">
              未報告日報
            </div>
          </v-card>
          <v-card
            v-else
            class="d-flex justify-center align-center"
            flat
            width="84px"
            height="24px"
            color="#D1FEC8"
          >
            <div class="font-14px font-333">
              報告済日報
            </div>
          </v-card>
          <div>
            <v-btn
              :to="{ name: 'drInput', params: { date: date } }"
              text
              class="font-14px text-331 mt-n3"
            >
              <v-icon class="pt-1" left size="12">$editGray</v-icon>
              編集する
            </v-btn>
          </div>
        </div>

        <sales-card
          :earnings="dailyReport.earnings"
          :achievementRate="dailyReport.achievement_rate"
          :cashSales="dailyReport.cash_sales"
          :cost="dailyReport.cost"
          :profit="dailyReport.profit"
        />
        <div class="pt-3"></div>
        <div class="d-flex ml-4 mb-3">
          <v-tabs v-model="dailySalesTab" class="sales-tab">
            <v-tab class="first-tab">
              当日売上
            </v-tab>
            <v-tab class="second-tab">
              ランチ売上
            </v-tab>
            <v-tab class="third-tab">
              ディナー売上
            </v-tab>
            <v-tab class="forth-tab">
              当日コスト
            </v-tab>
          </v-tabs>
        </div>

        <v-tabs-items v-model="dailySalesTab">
          <v-tab-item>
            <sales-tab
              v-if="dailyReport.today_sales"
              :tabFor="'Today Sales'"
              :report="dailyReport.today_sales"
              type="daily"
            />
          </v-tab-item>
          <v-tab-item>
            <sales-tab
              v-if="dailyReport.lunch_sales"
              :tabFor="'lunchSales'"
              :report="dailyReport.lunch_sales"
              type="daily"
            />
          </v-tab-item>
          <v-tab-item>
            <sales-tab
              v-if="dailyReport.dinner_sales"
              :tabFor="'dinnerSales'"
              :report="dailyReport.dinner_sales"
              type="daily"
            />
          </v-tab-item>
          <v-tab-item>
            <cost-tab v-if="dailyReport.cost" :cost="dailyReport.cost" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-card v-if="monthReport.earnings" class="mt-4">
        <div class="pb-3"></div>
        <sales-card
          :dateCard="true"
          :startDate="monthReport.date.from"
          :currentDate="monthReport.date.to"
          :month="monthReport.date.month"
          :earnings="monthReport.earnings"
          :achievementRate="monthReport.achievement_rate"
          :cashSales="monthReport.cash_sales"
          :cost="monthReport.cost"
          :profit="monthReport.profit"
        />
        <div class="pt-3"></div>
        <div class="d-flex justfiy-center pl-1 mx-3 mb-3">
          <v-tabs v-model="monthlySalesTab" class="sales-tab">
            <v-tab class="first-tab">
              累計売上
            </v-tab>
            <v-tab class="second-tab">
              ランチ売上
            </v-tab>
            <v-tab class="third-tab">
              ディナー売上
            </v-tab>
            <v-tab class="forth-tab">
              累計コスト
            </v-tab>
          </v-tabs>
        </div>

        <v-tabs-items v-model="monthlySalesTab">
          <v-tab-item>
            <sales-tab
              v-if="monthReport.all_day_sales"
              :tabFor="'sameDaySales'"
              :report="monthReport.all_day_sales"
              type="monthly"
            />
          </v-tab-item>
          <v-tab-item>
            <sales-tab
              v-if="monthReport.lunch_sales"
              :tabFor="'lunchSales'"
              :report="monthReport.lunch_sales"
              type="monthly"
            />
          </v-tab-item>
          <v-tab-item>
            <sales-tab
              v-if="monthReport.dinner_sales"
              :tabFor="'dinnerSales'"
              :report="monthReport.dinner_sales"
              type="monthly"
            />
          </v-tab-item>
          <v-tab-item>
            <cost-tab v-if="monthReport.cost" :cost="monthReport.cost" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card>
    <div class="d-flex justify-center" v-if="dailyReport.month">
      <v-card flat color="transparent" class="position-button mx-auto">
        <div class="">
          <v-icon @click="$router.push({ name: 'drList' })" size="40"
            >$cancel</v-icon
          >
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import SalesCard from "@/components/layout/daily-report/SalesCard";
import SalesTab from "@/components/layout/daily-report/SalesTab";
import CostTab from "@/components/layout/daily-report/CostTab";
import dayjs from "@/plugins/dayjs";

export default {
  name: "drView",
  components: {
    SalesCard,
    SalesTab,
    CostTab
  },
  data() {
    return {
      dailySalesTab: null,
      monthlySalesTab: null,
      dailyReport: {},
      monthReport: {},
      isReported: false
    };
  },
  computed: {
    pageLoading() {
      return !this.$store.state.report.status;
    },
    getShop() {
      return this.$store.getters.getFirstShopObject;
    },
    getShopAuthUser() {
      return this.$store.getters.getShopAuthUser[0];
    },
    dayName() {
      return dayjs()
        .month(this.dailyReport.month - 1)
        .date(this.dailyReport.day)
        .year(this.dailyReport.year)
        .format("ddd");
    }
  },
  async mounted() {
    this.date = this.$route.params.id;
    this.shopId = this.getShop.id;
    await this.getCumulatedReport();
  },
  methods: {
    getDailyReport(response) {
      this.dailyReport = response.data.data.daily_report;
      this.isReported = this.dailyReport?.is_reported;
    },
    async getCumulatedReport() {
      await this.$store
        .dispatch("GET_CUMULATED_REPORT_BY_DATE", {
          shop_id: this.shopId,
          date: this.date
        })
        .then(response => {
          this.dailyReport = response.data.data.daily_report;
          this.isReported = this.dailyReport?.is_reported;
          this.monthReport = response.data.data.cumulated_report;
        });
    },
    async previousDayReport() {
      const prevDate = dayjs(this.date)
        .subtract(1, "day")
        .format("YYYY-M-D");
      this.$router.push({
        name: "drView",
        params: {
          id: prevDate
        }
      });
    },
    async nextDayReport() {
      const nextDate = dayjs(this.date)
        .add(1, "day")
        .format("YYYY-M-D");

      this.$router.push({
        name: "drView",
        params: {
          id: nextDate
        }
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
