<template>
  <div>
    <v-card class="py-3">
      <h1 class="text-center font-22px mb-7 mt-4">
        {{ formatDate(reportDate, "YYYY年MM月DD日") }}
      </h1>
      <p class="text-center">この内容で日報を保存しますか？</p>
      <div class="text-center my-2 mb-6 full-width">
        <span
          v-if="isReported == '0'"
          class="rounded text-333 px-2 line-height-16 label-red"
          >この日報は未報告です。</span
        >
        <span v-else class="rounded text-333 px-2 line-height-16 label-green"
          >この日報は報告済です。</span
        >
      </div>
      <!-- lunch sales -->
      <template v-if="lunchSales !== null && lunchSales.food_sales">
        <div
          class="d-flex justify-space-between align-center px-2 mx-6 py-1 my-9 light-yellow"
        >
          <v-row align="center" class="px-2">
            <v-col cols="5">
              <span class="sun-greenish d-flex align-center ml-1">
                <v-icon size="20" class="sun-greenish mr-1s">$sunAlt</v-icon
                ><span class="font-16px">ランチ</span>
              </span>
            </v-col>
            <v-col justify="end">
              <div class="d-flex justify-end mr-3">
                <span v-if="user.s_account_id"
                  >報告者：{{ sharedAccountUser }}</span
                >
                <span v-else>報告者：{{ `${user.surname} ${user.name}` }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>

      <div class="mx-9 text-div">
        <template v-if="lunchSales !== null && lunchSales.food_sales">
          <p class="border-dahsed">
            フード：{{ lunchSales.food_sales | formatMoney }}
          </p>
          <p class="border-dahsed">
            ドリンク：{{ lunchSales.drink_sales | formatMoney }}
          </p>
          <p class="border-dahsed">
            デリバリー：{{ lunchSales.delivery_sales | formatMoney }} （{{
              lunchSales.number_of_delivery
            }}件）
          </p>
          <p class="border-dahsed">
            テイクアウト：{{ lunchSales.takeout_unit_sales | formatMoney }} （{{
              lunchSales.number_of_takeout
            }}件）
          </p>
          <p class="border-dahsed mb-8">
            その他：{{ lunchSales.others_sales | formatMoney }}
          </p>
          <p class="border-dahsed d-flex justify-space-between">
            <span
              >店内利用客数：{{ lunchSales.number_of_customer_store }}人</span
            >
            <span>総客数：{{ lunchSales.number_of_total_customer }}人</span>
          </p>
          <p class="border-dahsed d-flex justify-space-between mb-8">
            <span>店内利用組数：{{ lunchSales.number_of_group_store }}組</span>
            <span> 総組数：{{ lunchSales.number_of_total_group }}組</span>
          </p>
          <p class="border-dahsed mb-8">
            合計アルバイト代：{{ lunchSales.payment | formatMoney }}
          </p>
          <p class="border-dahsed mb-8">
            主な天気：{{ lunchSales.weather | weatherName }}
          </p>
          <template v-if="lunchSales.notes">
            <p class="">報告内容：</p>
            <p class="mb-10 text-pre-wrap">
              {{ lunchSales.notes }}
            </p>
          </template>
        </template>
        <!-- this is the dinner sales -->
        <template v-if="dinnerSales && dinnerSales.food_sales">
          <div
            class="d-flex justify-space-between align-center px-2 py-1 moon-div mb-7"
          >
            <v-row align="center" class="px-2">
              <v-col cols="5">
                <span class="d-flex align-center">
                  <v-icon size="20" class="sun-greenish mr-1">$moon</v-icon
                  ><span class="blue-text font-16px">ディナー</span>
                </span>
              </v-col>
              <v-col>
                <span v-if="user.s_account_id"
                  >報告者：{{ sharedAccountUser }}</span
                >
                <span v-else
                  >報告者：
                  {{ `${user.surname} ${user.name}` }}
                </span>
              </v-col>
            </v-row>
          </div>

          <p class="text-primary fw-700 mb-0">ディナー売上について</p>
          <p class="font-12px">
            登録時に入力した値を用いて、以下のように算出された値が、ディナー売上として登録されています。
          </p>
          <div class="light-yellow py-2 px-1 mt-3 mb-8 font-12px text-center">
            ( 当日売上 ) ー ( ランチ売上 ) ＝ ( ディナー売上 )
          </div>

          <!-- same as above may be re-written as loop -->
          <p class="border-dahsed">
            フード：{{ dinnerSales.food_sales | formatMoney }}
          </p>
          <p class="border-dahsed">
            ドリンク：{{ dinnerSales.drink_sales | formatMoney }}
          </p>
          <p class="border-dahsed">
            デリバリー：{{ dinnerSales.delivery_sales | formatMoney }} （{{
              dinnerSales.number_of_delivery
            }}件）
          </p>
          <p class="border-dahsed">
            テイクアウト：{{
              dinnerSales.takeout_unit_sales | formatMoney
            }}
            （{{ dinnerSales.number_of_takeout }}件）
          </p>
          <p class="border-dahsed mb-8">
            その他：{{ dinnerSales.others_sales | formatMoney }}
          </p>
          <p class="border-dahsed d-flex justify-space-between">
            <span
              >店内利用客数：{{ dinnerSales.number_of_customer_store }}人</span
            >
            <span>総客数：{{ dinnerSales.number_of_total_customer }}人</span>
          </p>
          <p class="border-dahsed d-flex justify-space-between mb-8">
            <span>店内利用組数：{{ dinnerSales.number_of_group_store }}組</span>
            <span> 総組数：{{ dinnerSales.number_of_total_group }}組</span>
          </p>
          <p class="border-dahsed mb-8">
            合計アルバイト代：{{ dinnerSales.payment | formatMoney }}
          </p>
          <p class="border-dahsed mb-8">
            主な天気：{{ dinnerSales.weather | weatherName }}
          </p>

          <template v-if="dinnerSales.notes">
            <p class="">報告内容：</p>
            <p class="border-dahsed mb-8 text-pre-wrap">
              {{ dinnerSales.notes }}
            </p>
          </template>
        </template>
        <template v-if="cashSales">
          <p class="mb-10 ">
            現金売上：{{ parseInt(cashSales) | formatMoney }}
          </p>
        </template>
      </div>
    </v-card>

    <v-row class="ma-0 mb-10 mt-3">
      <v-col class="px-5 pt-0 pb-0 " cols="12" md="12">
        <div class="mb-3">
          <p v-if="isReported == '0'" class="ma-0 red--text font-14px">
            この日報は未報告です。
          </p>
          <p v-else class="ma-0 green--text font-14px">
            この日報は報告済です。
          </p>
          <p v-if="isReported == '0'" class="ma-0 text-91 font-12px">
            報告&保存をすると、メーリングリストに登録されたメンバーに日報の更新が通知されます。
          </p>
          <p v-else class="ma-0 text-91 font-12px">
            報告&保存をタップすると、メーリングリストに登録されたメンバーに日報の更新が通知されます。
          </p>
        </div>

        <v-btn
          type="submit"
          color="primary"
          block
          class="fw-700"
          @click="submitReport(1)"
          :loading="loading"
        >
          報告 & 保存
        </v-btn>
      </v-col>

      <v-col class="px-5 d-flex justify-space-between">
        <v-btn
          type="button"
          color="white"
          class="primary--text border-primary fw-700"
          width="146"
          @click.stop="closeConfirm"
          :disabled="loading"
        >
          戻る
        </v-btn>
        <v-btn
          color="#0094FF"
          type="submit"
          width="146"
          dark
          class="fw-700"
          @click="submitReport(0)"
          :loading="loading"
        >
          報告せず保存
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "DrConfirm",
  props: {
    lunchSales: Object,
    dinnerSales: Object,
    shopId: Number,
    reportDate: Object,
    submitReport: Function,
    isReported: [Boolean, Number, String],
    closeConfirm: Function,
    cashSales: [Number, String],
    loading: [Boolean],
    sharedAccountId: [String, Number]
  },
  computed: {
    ...mapGetters(["user"]),
    sharedAccountUser() {
      let userName = "";
      this.user.shared_accounts.map((user, key) => {
        if (user.id == this.sharedAccountId) {
          userName = user.name;
        }
      });

      return userName;
    }
  },
  filters: {
    weatherName: value => {
      switch (value) {
        case 1:
          return "晴れ";
        case 2:
          return "曇り";
        case 3:
          return "雨";

        default:
          value;
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
