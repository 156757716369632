<template>
  <ICountUp
    :delay="delay"
    :end-val="endVal"
    :options="options"
    @ready="onReady"
  />
</template>

<script>
import ICountUp from "vue-countup-v2";
export default {
  name: "CounterUpCard",
  components: {
    ICountUp
  },
  props: {
    endVal: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      delay: 1000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: ""
      }
    };
  },
  methods: {
    onReady(instance, CountUp) {
      const that = this;
      instance.update(that.endVal);
    }
  }
};
</script>

<style scoped></style>
